@use "react-credit-cards/lib/styles";
@import "fontsource-spartan/index.css";

/* Colours */
$primary-color: #1d71b8;
$primary-contrast: white;

/* Breakpoints and geometry */
$breakpoint-medium: 1024px;
$breakpoint-small: 768px;
$radius: 5px;

html {
	font-family: "Spartan", sans-serif;
	font-size: 13px;
	color: #333;
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

/* "Frame" layout display with separate header and body etc, filling the whole viewport */

body {
	margin: 0;
	padding: 0;
	width: 100%;
	height: 100vh;
	display: flex;
	flex-direction: column;

	#root {
		flex: auto;
		display: flex;
		overflow: hidden;
	}
}

/* General typography */

a {
	color: $primary-color;
	text-decoration: none;
	border-bottom: 4px solid transparent;
	transition: border-bottom-color 0.3s ease-out;
	padding: 0.25rem 0;

	&:hover,
	&.active {
		border-bottom: 4px solid #EEE;
	}
}

h1,
h2,
h3,
h4 {
	margin-top: 0;
}

/* General forms */
button {
	border: none;
	border-radius: $radius;
	background-color: $primary-color;
	color: $primary-contrast;
	padding: 0.5rem 1.5rem;
}

input[type=text],
input[type=email],
input[type=number],
input[type=tel],
input[type=date],
select {
	border-radius: 0;
	border: 1px solid #CCC;
	padding: 0.5rem;
	color: #444;
}

/* Some input fields are better with fixed-length */
.card-details input {
	font-size: 1.25em;
	font-family: "Courier New", fixed;
}

.editor .gui>section {
	margin: 0;
	padding: 1rem;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	width: 100%;
	max-width: 40rem;

	&:first-of-type {
		margin-top: 1rem;
	}

	&:not(:first-of-type) {
		border-top: 1px solid #EEE;
	}

	.field {
		flex: auto;
		display: flex;
		margin-bottom: 1rem;

		label {
			font-size: 0.9em;
			flex: none;
			display: flex;
			align-content: center;
			align-items: center;
			width: 10rem;
			overflow: hidden;
			text-overflow: ellipsis;

			@media (max-width: $breakpoint-small) {
				// On tiny screens make smaller
				width: 8rem;
			}
		}

		input[type=text],
		input[type=email],
		input[type=tel],
		input[type=number],
		input[type=date],
		select {
			display: block;
			flex: auto;
			min-width: 0;
			width: 2rem;
		}
	}

	.fieldgroup {
		@media (min-width: $breakpoint-small) {
			// On larger devices, show grouped fields on single line
			display: flex;
			margin-bottom: 1rem;

			.field {
				flex: 1;

				&:not(:first-child) {
					label {
						/* Small, auto-sized, align right */
						width: auto;
						margin-left: 2rem;
						margin-right: 1rem;
					}
				}
			}
		}
	}
}


/* General, re-usable page layout elements */
footer.actions {
	flex: none;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: white;
	z-index: 100;
	box-shadow: 0 0 30px rgba(0, 0, 0, 0.05);

	button {
		margin: 1rem;

		&.reset {
			color: #777;
			background-color: #DDD;
		}
	}
}

header.app {
	background-color: white;
	flex: none;
	display: flex;
	align-items: center;
	height: 6rem;
	overflow: hidden;
	z-index: 100;
	box-shadow: 0 0 30px rgba(0, 0, 0, 0.05);

	/* One or more stretchy things */
	>* {
		flex: auto;
	}

	/* Navbar to the right, non-stretchy */
	>nav {
		flex: auto;
		display: flex;
		padding-right: 2rem;
		justify-content: center;

		ul {
			display: flex;
			margin: 0;
			padding: 0;
			align-items: center;

			li {
				display: block;
				margin: 0;
				padding: 1rem;
				font-weight: bold;

				a {
					padding-top: 0.75rem;
					padding-bottom: 0.75rem;

					@media (max-width: $breakpoint-small) {

						// Hide extranous link text on tiny devices
						.exp {
							display: none;
						}
					}
				}
			}
		}
	}

	>.brand {
		padding: 0 2rem 0 3rem;

		.logo {
			margin: 0 0 0.25rem 0;
			padding: 0;
			/* Replace with logo */
			width: 16rem;
			height: 3rem;
			overflow: hidden;
			background-repeat: no-repeat;
			background-position: left center;
			background-size: contain;
		}

		h2 {
			margin: 0;
			padding: 0;
			font-size: 0.8rem;
			letter-spacing: 3px;
			color: #999;
		}

		@media (max-width: $breakpoint-small) {

			// On small screens, make branding smaller
			h1 {
				width: 12rem;
				height: 2.5rem;
			}

			h2 {
				display: none;
			}
		}
	}
}

/* Specific views */
.sale-request {
	flex: auto;
	display: flex;
	flex-direction: column;
	background-color: #fcfcfc;
	overflow-x: hidden;
	overflow-y: auto;

	.editor {
		// Side-by side, with GUI on left, JSON on right. Each can scroll vertically, independently
		flex: auto;
		display: flex;
		flex-direction: row;
		overflow: hidden;

		.gui {
			flex: 1;
			display: flex;
			flex-direction: column;
			overflow-x: hidden;
			overflow-y: auto;
			padding: 2rem;
			align-items: center;

			.field.cvv,
			.field.currency {
				@media (min-width: $breakpoint-small) {
					// Form tweaks
					flex: none;
					min-width: 14rem;
					width: 5rem;
				}
			}
		}

		.code {
			flex: 1;
			overflow-x: hidden;
			overflow-y: auto;
		}

		@media (max-width: $breakpoint-medium) {

			// On medium screens, make JSON area smaller
			.gui {
				flex: 4;
			}

			.code {
				overflow-x: auto;
				flex: 3;
			}
		}

		@media (max-width: $breakpoint-small) {
			// On small screens, move JSON to below, and
			// no longer scroll individually within gui and code
			flex-direction: column;
			overflow-y: auto;

			.gui {
				overflow: visible;
			}

			.code {
				overflow: visible;
			}
		}
	}
}

.sale-transaction {
	flex: auto;
	display: flex;
	flex-direction: column;
	background-color: #fcfcfc;
	overflow-x: hidden;
	overflow-y: auto;

	.verification-required {
		flex: auto;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		// Embedded iframe, floating in center
		.render_html,
		.visit_url {
			flex: auto;
			display: flex;
			flex-direction: column;
			align-items: center;
			width: 100%;
			padding: 2rem;

			p {
				margin: none;
				padding: 0;
				text-align: center;
			}

			iframe {
				border: 8px solid white;
				flex: auto;
				margin: 2rem;
				padding: 0;
				border-radius: 2 * $radius;
				box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.2);
				width: 90%;
				max-width: 60rem;
			}


		}

	}

	.complete {
		flex: auto;
		display: flex;
		flex-direction: column;
		overflow-x: hidden;
		overflow-y: auto;

		.response {
			// Side-by side, with GUI on left, JSON on right. Each can scroll vertically, independently
			flex: auto;
			display: flex;
			flex-direction: row;
			overflow: hidden;

			.gui {
				flex: 1;
				display: flex;
				flex-direction: column;
				overflow-x: hidden;
				overflow-y: auto;
				padding: 2rem;
				align-items: center;

				table {
					width: 100%;
					border-collapse: collapse;

					tr {
						td {
							border-bottom: 1px solid #DDD;
							padding: 1rem 0;

							&.val {
								text-align: right;
								font-family: "Courier new", fixed;
							}
						}

						&.error {
							color: red;

							.val {
								font-weight: bold;
							}
						}
					}
				}
			}

			.code {
				flex: 1;
				// No scrolling here, scrolling inside the code box
				overflow: hidden;

				.selector {
					select {
						width: 100%;
					}
				}
			}
		}
	}
}


// Stand-alone display of score summary - pie chart to the left, floating per-category boxes to the right
.score-result-summary {
	flex: none;
	display: flex;
	background-color: white;

	.grade {
		padding: 1rem;
	}

	.categories {
		flex: auto;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-items: center;
		justify-content: center;

		.category {
			width: 6rem;
			height: 6rem;
			border: 2px solid #CCC;
			margin: 1rem;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			&.good {
				background-color: #98E798;
			}

			&.ok {
				background-color: #E4E798;
			}

			&.bad {
				background-color: #E79899;
			}

			.key {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				font-size: 0.9em;
			}

			.adj {
				margin: 0.5rem;
				font-family: "Courier New", fixed;
				font-weight: bold;
			}
		}
	}
}